<template>
  <div>
    <tool-bar></tool-bar>
    <main><router-view :key="$route.fullPath"></router-view></main>
    <foot-bar></foot-bar>
  </div>
</template>

<script>
    import ToolBar from '@/components/ToolBar.vue';
    import FootBar from '@/components/FootBar.vue';
    import Vue from 'vue';
    import {mapState, mapMutations, mapActions} from 'vuex';

    export default {
        name: 'Index',
        components: {
            ToolBar,
            FootBar,
        },
        computed:{
            ...mapState(['empresa', 'sucursales', 'sucursal_seleccionada']),
        },
        data(){
            return{
                loadProperty:false,
                dataProperty:{
                    company:{},
                },
                dataNotification:{},
            }
        },
        created(){
            this.loadObjEmpresa();
            this.loadObjSucursalSeleccionada();
        },
        mounted(){
            var listSucursales=[];
            $.each(this.sucursales, function(key, value) {
                listSucursales.push(value.id);
            });
            //console.log(this.$route, "index.vue");
            /**
             * name = principal
             * name = catalogo
             */

            if(this.sucursal_seleccionada){
                if(this.$route.name=="inicio"){
                    console.log("redirigir a0:", window.location.origin+"/tienda/"+this.sucursal_seleccionada);
                    location.href = window.location.origin+"/tienda/"+this.sucursal_seleccionada;
                    return;
                }
                
                else if(this.$route.name=="tienda"){
                    if(this.$route.params.sucursal && listSucursales.length>0){
                        if(listSucursales.indexOf(this.$route.params.sucursal)==-1){
                            console.log("redirigir a1:", window.location.origin+"/tienda/"+this.sucursal_seleccionada);
                            //location.href = window.location.origin+"/tienda/"+this.sucursal_seleccionada;
                            return;
                        }
                    }else{
                        if(this.sucursal_seleccionada!=this.$route.params.sucursal){
                            console.log("redirigir a2:", window.location.origin+"/tienda/"+this.sucursal_seleccionada);
                            //location.href = window.location.origin+"/tienda/"+this.sucursal_seleccionada;
                            return;
                        }
                    }
                }else{
                    if(this.$route.name=="NotFound" && (this.$route.path=="/tienda" || this.$route.path=="/tienda/")){
                        console.log("redirigir a3:", window.location.origin+"/tienda/"+this.sucursal_seleccionada);
                        //location.href = window.location.origin+"/tienda/"+this.sucursal_seleccionada;
                        return;
                    }
                }
            }else{
                console.log("No existe sucursal seleccionada :(");
                this.getPropiedades();
            }
        },
        methods:{
            ...mapMutations(['setLoading','setEmpresa', 'setCategorias', 'setRedesSociales', 'setSucurales', 'setSucursalSeleccionada', 'setMarcas']),
            ...mapActions(['loadObjEmpresa', 'loadObjSucursalSeleccionada']),
            getPropiedades(){
                this.$http.get('tiendaVirtual/propiedades_empresa').then((res)=>{
                    Vue.set(this.$data,'dataProperty',res.data);
                    this.loadProperty=true;
                    this.setEmpresa(this.dataProperty.company);
                    this.setCategorias(this.dataProperty.category);
                    this.setMarcas(this.dataProperty.brand);
                    this.setRedesSociales(this.dataProperty.company.redes_sociales);
                    this.setSucurales(this.dataProperty.company.sucursales);

                    let sucursal_select = null;
                    console.log(this.$route);
                    if(this.$route.name=="inicio"){
                        if(this.sucursales.length>0){
                            sucursal_select = this.sucursales[0].id;
                            this.setSucursalSeleccionada(sucursal_select);
                            location.href = window.location.origin+"/tienda/"+sucursal_select;
                        }
                    }
                });
            },
        },
    }
</script>