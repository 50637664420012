import Vue from 'vue'
import Vuex from 'vuex'

import { objectToFormData } from '../formData'

Vue.use(Vuex)
let carrito_sin_login = window.localStorage.getItem('carrito_sin_login'+process.env.VUE_APP_COMPANY);
let carrito_con_login = window.localStorage.getItem('carrito_con_login'+process.env.VUE_APP_COMPANY);
let mi_cuenta         = window.localStorage.getItem('mi_cuenta'+process.env.VUE_APP_COMPANY);

export default new Vuex.Store({
  state: {
    loading:true,
    empresa:{
      razon_social:"",
      abreviatura:"",
      ruc:"",
      telefonos:null,
      url_file_logo:"",
      sucursales:[],
      redes_sociales:[],
    },
    sucursal_seleccionada:null,
    redes_sociales:[],

    categorias:[],    //Lista de categorias general
    marcas:[],        //Lista de marcas general

    mi_cuenta:{
      cli_id:"",
      api_token_carrito:"",
      nick:"",
      nombres:"",
      first_name:"",
    },
    carrito: (mi_cuenta?(carrito_con_login ? JSON.parse(carrito_con_login) : []):(carrito_sin_login ? JSON.parse(carrito_sin_login) : [])),
    carrito_sin_login: carrito_sin_login ? JSON.parse(carrito_sin_login) : [],
    carrito_con_login:carrito_con_login ? JSON.parse(carrito_con_login) : [],
    total_con_login:0,
    total_sin_login:0,

    orderItem: "",
    orderType:"",
    producto_filtro:"",   //Filtro de productos
    categoria_filtro:[],  //Filtro de categorias
    marca_filtro:[],
    precio_filtro:[],
    sucursal_filtro:"",
  },
  mutations: {
    setLoading:(state,value)=>{
      Vue.set(state,'loading',value);
    },
    setEmpresa:(state,obj)=>{
      Vue.set(state,'empresa',obj);
      localStorage.setItem('empresaTiendaClaro'+process.env.VUE_APP_COMPANY, JSON.stringify(obj));
    },

    setRedesSociales:(state,obj)=>{
      Vue.set(state,'redes_sociales',obj);
      localStorage.setItem('redes_sociales'+process.env.VUE_APP_COMPANY, JSON.stringify(obj));
    },

    setMarcas:(state,obj)=>{
      Vue.set(state,'marcas',obj);
      localStorage.setItem('marcas'+process.env.VUE_APP_COMPANY, JSON.stringify(obj));
    },

    setCategorias:(state,obj)=>{
      Vue.set(state,'categorias',obj);
      localStorage.setItem('categorias'+process.env.VUE_APP_COMPANY, JSON.stringify(obj));
    },

    setSucurales:(state,obj)=>{
      Vue.set(state,'sucursales',obj);
      localStorage.setItem('sucursales'+process.env.VUE_APP_COMPANY, JSON.stringify(obj));
    },
    setSucursalSeleccionada:(state,value)=>{
      Vue.set(state,'sucursal_seleccionada',value);
      localStorage.setItem('sucursal_seleccionada'+process.env.VUE_APP_COMPANY, value);
    },
    setPerfilUsuario:(state,obj)=>{
      Vue.set(state,'mi_cuenta',obj);
      localStorage.setItem('mi_cuenta'+process.env.VUE_APP_COMPANY, JSON.stringify(obj));
    },
    borrarSesion(state){
      state.mi_cuenta = {
        cli_id:"",
        api_token_carrito:"",
        nick:"",
        nombres:"",
        first_name:"",
      }
      localStorage.removeItem("mi_cuenta"+process.env.VUE_APP_COMPANY);
      localStorage.removeItem("carrito_con_login"+process.env.VUE_APP_COMPANY);
    },
    agregarCarrito(state,{producto,cantidad, reemplazar_cantidad, sucursal, incremento}){
      let index;
      let state_carrito;
      let producto_seleccionado;
      cantidad = Number(cantidad);

      if(Boolean(state.mi_cuenta.cli_id) && Boolean(state.mi_cuenta.api_token_carrito)){
        state_carrito = state.carrito_con_login;
        //console.log("Agregando item Deste el store, Con usuario logueado", state_carrito);
      }else{
        state_carrito = state.carrito_sin_login;
        //console.log("Agregando item Deste el store, sin usuario logueado", state_carrito);
      }
      //console.log(state_carrito);
      index = state_carrito.findIndex((item)=>{
        return item.prod_id===producto.prod_id;
      });
      
      //Cuando el producto no existe en el carrito
      if(index===-1){
        
        let objeto_producto = {
          prod_id                   :producto.prod_id,
          cod_articulo              :producto.cod_articulo,
          url_imagen                :producto.url_imagen,
          nombre_producto           :producto.nombre_producto,
          categoria                 :producto.categoria,
          precio_venta              :Number(producto.precio_venta_real).toFixed(2),
          cantidad                  :Number(cantidad),
          stock                     :Number(producto.stock),
        };
        
        //Enviar carrito con producto nuevo a la BD con cliente logueado
        if(Boolean(state.mi_cuenta.cli_id) && Boolean(state.mi_cuenta.api_token_carrito)){
          this.dispatch(
            "guardarCarritoCliente"
            , { vm: this._vm, producto: objeto_producto, cliente:state.mi_cuenta, sucursal, incremento: incremento }
          )
          .then(res=>{
            if(res){
              //console.log("Agregando producto nuevo al carrito del cliente logueado");
              state_carrito.push(objeto_producto);
              state.carrito = state_carrito;

              window.localStorage.setItem("carrito_con_login"+process.env.VUE_APP_COMPANY, JSON.stringify(state_carrito));
              toastr.success("<b>"+objeto_producto.nombre_producto+"</b>, agregado al carrito!",'Mensaje');
              return;
            }
            toastr.error("Hubo problemas al guardar la operacion",'Advertencia');
          })
          .catch(e=>{
            console.log(e);
            if(e && e.response && e.response.status==422){ //Errores de Validacion
              const mapErrors ={};
              for (let field of Object.keys(e.response.data.errors)) {
               mapErrors[field]=e.response.data.errors[field][0];

                toastr.error(mapErrors[field],'Advertencia');
              }
            }
          });

          return;
        }

        producto_seleccionado = objeto_producto;
        //"Agregando producto nuevo al carrito del cliente no logueado"
        state_carrito.push(objeto_producto);
        state.carrito = state_carrito;
        window.localStorage.setItem("carrito_sin_login"+process.env.VUE_APP_COMPANY, JSON.stringify(state_carrito));
        toastr.success(objeto_producto.nombre_producto+" agregado al carrito!",'Mensaje');
        return;
      }
      
      //Enviar cantidad modificada del producto al carrito de cliente logueado
      if(Boolean(state.mi_cuenta.cli_id) && Boolean(state.mi_cuenta.api_token_carrito)){
        let nueva_cantidad = 0;

        if(reemplazar_cantidad){
          nueva_cantidad = cantidad;
        }else{
          if(incremento)
            nueva_cantidad = state_carrito[index].cantidad+cantidad;
          else
            nueva_cantidad = state_carrito[index].cantidad-cantidad;
        }
        
        let objeto_producto = state_carrito[index];

        if(nueva_cantidad>objeto_producto.stock){
          toastr.error("<b>"+objeto_producto.nombre_producto+"</b>, sólo cuenta con "+objeto_producto.stock+" unidades disponibles",'Advertencia');
          return;
        }
        objeto_producto.cantidad = nueva_cantidad;
        producto_seleccionado = objeto_producto;
        this.dispatch(
          "guardarCarritoCliente"
          , { vm: this._vm, producto: objeto_producto, cliente:state.mi_cuenta, sucursal, incremento: incremento }
        )
        .then(res=>{
          if(res){
            //console.log("Agregando cantidad modificado al carrito del cliente logueado");
            Vue.set(state_carrito[index],'cantidad',nueva_cantidad);
            state.carrito = state_carrito;
            window.localStorage.setItem("carrito_con_login"+process.env.VUE_APP_COMPANY, JSON.stringify(state_carrito));
            toastr.success("<b>"+objeto_producto.nombre_producto+"</b>, agregado al carrito!",'Mensaje');
            return;
          }
          toastr.error("Hubo problemas al guardar la operacion",'Advertencia');
        })
        .catch(e=>{
          if(e && e.response && e.response.status==422){ //Errores de Validacion
            const mapErrors ={};
            for (let field of Object.keys(e.response.data.errors)) {
              mapErrors[field]=e.response.data.errors[field][0];

              toastr.error(mapErrors[field],'Advertencia');
            }
          }
        });
      }

      //Modificando carrito de cliente no logueado
      if(reemplazar_cantidad){
        Vue.set(state_carrito[index],'cantidad',cantidad);
      }else{
        if(incremento)
          Vue.set(state_carrito[index],'cantidad',state_carrito[index].cantidad+cantidad);
        else
          Vue.set(state_carrito[index],'cantidad',state_carrito[index].cantidad-cantidad);
      }
      state.carrito = state_carrito;
      window.localStorage.setItem("carrito_sin_login"+process.env.VUE_APP_COMPANY, JSON.stringify(state_carrito));
      //toastr.success("<b>"+producto_seleccionado.nombre_producto+"</b>, agregado al carrito!",'Mensaje');
    },
    borrarItemCarrito(state, producto) {
      let index;
      let state_carrito;

      if(Boolean(state.mi_cuenta.cli_id) && Boolean(state.mi_cuenta.api_token_carrito)){
        state_carrito = state.carrito_con_login;
        //"Borrando item Deste el store, Con usuario logueado"
      }

      index = state_carrito.findIndex((item)=>{
        return item.prod_id===producto.prod_id;
      });
      
      if(index!==-1){
        //Borrando producto con cliente logueado
        if(Boolean(state.mi_cuenta.cli_id) && Boolean(state.mi_cuenta.api_token_carrito)){
          this.dispatch(
            "borrarProductoCarritoCliente"
            , { vm: this._vm, producto: {prod_id:producto.prod_id}, cliente:state.mi_cuenta}
          )
          .then(res=>{
            if(res){
              //Borrando producto sin cliente logueado
              state_carrito.splice(index, 1);
              state.carrito = state_carrito;
              window.localStorage.setItem("carrito_con_login"+process.env.VUE_APP_COMPANY, JSON.stringify(state_carrito));

              toastr.success("<b>"+producto.nombre_producto+"</b>, eliminado del carrito!",'Mensaje');
              return;
            }
            toastr.error("Hubo problemas al guardar la operacion",'Advertencia');
          })
          .catch(e=>{
            if(e && e.response && e.response.status==422){ //Errores de Validacion
              const mapErrors ={};
              for (let field of Object.keys(e.response.data.errors)) {
                mapErrors[field]=e.response.data.errors[field][0];

                toastr.error(mapErrors[field],'Advertencia');
              }
            }
          });

          return;
        }
      }
    },

    setFiltroCategoria(state, obj){
      state.categoria_filtro = obj;
    },
    setFiltroMarca(state, obj){
      state.marca_filtro = obj;
    },
    setFiltroProducto(state, obj){
      state.producto_filtro = obj;
    },
    setFiltroPrecio(state, obj){
      state.precio_filtro = obj;
    },
    setFiltroSucursal(state, obj){
      state.sucursal_filtro = obj;
    },
    setFiltroOrderItem(state, obj){
      state.orderItem = obj;
    },
    setFiltroOrderType(state, obj){
      state.orderType = obj;
    },
    setCarritoNocliente:(state,obj)=>{
      Vue.set(state,'carrito_sin_login',obj);
      localStorage.setItem('carrito_sin_login'+process.env.VUE_APP_COMPANY, JSON.stringify(obj));
    },
    setCarritocliente:(state,obj)=>{
      Vue.set(state,'carrito_con_login',obj);
      localStorage.setItem('carrito_con_login'+process.env.VUE_APP_COMPANY, JSON.stringify(obj));

      //state.carrito = obj;
    },
  },
  actions: {
    loadObjEmpresa({commit, dispatch, getters}){
      let ObjEmpresa= localStorage.getItem('empresaTiendaClaro'+process.env.VUE_APP_COMPANY);
      if(ObjEmpresa){
        ObjEmpresa = JSON.parse(ObjEmpresa);
        commit('setEmpresa',ObjEmpresa);
      }
    },
    loadObjRedesSociales({commit, dispatch, getters}){
      let ObjRedesSociales= localStorage.getItem('redes_sociales'+process.env.VUE_APP_COMPANY);
      if(ObjRedesSociales){
        ObjRedesSociales = JSON.parse(ObjRedesSociales);
        commit('setRedesSociales',ObjRedesSociales);
      }
    },
    loadObjSucursales({commit, dispatch, getters}){
      let ObjSucurales= localStorage.getItem('sucursales'+process.env.VUE_APP_COMPANY);
      if(ObjSucurales){
        ObjSucurales = JSON.parse(ObjSucurales);
        commit('setSucurales',ObjSucurales);
      }
    },
    loadObjSucursalSeleccionada({commit, dispatch, getters}){
      let ObjSucuralesSelected= localStorage.getItem('sucursal_seleccionada'+process.env.VUE_APP_COMPANY);
      if(ObjSucuralesSelected){
        commit('setSucursalSeleccionada',ObjSucuralesSelected);
      }
    },
    loadObjMarca({commit, dispatch, getters}){
      let ObjMarca= localStorage.getItem('marcas'+process.env.VUE_APP_COMPANY);
      if(ObjMarca){
        ObjMarca = JSON.parse(ObjMarca);
        commit('setMarcas',ObjMarca);
      }
    },
    loadObjCategoria({commit, dispatch, getters}){
      let ObjCategoria= localStorage.getItem('categorias'+process.env.VUE_APP_COMPANY);
      if(ObjCategoria){
        ObjCategoria = JSON.parse(ObjCategoria);
        commit('setCategorias',ObjCategoria);
      }
    },
    loadObjPerfilUsuario({commit, dispatch, getters}){
      let ObjCliente= localStorage.getItem('mi_cuenta'+process.env.VUE_APP_COMPANY);

      if(ObjCliente){
        ObjCliente = JSON.parse(ObjCliente);
        commit('setPerfilUsuario',ObjCliente);
      }
    },
    loadObjCarrito({commit, dispatch, getters}){
      let ObjCarrito= localStorage.getItem('carrito_con_login'+process.env.VUE_APP_COMPANY);

      if(ObjCarrito){
        ObjCarrito = JSON.parse(ObjCarrito);
        commit('setCarritocliente',ObjCarrito);
      }
    },

    guardarCarritoCliente({ commit }, {vm, producto, cliente, sucursal, incremento}){
      commit('setLoading', true);

      let post_object = {
         ...{
          cod_producto: producto.prod_id
          ,cantidad: producto.cantidad
          ,precio_venta: producto.precio_venta
          ,sucursal: sucursal
        }
        ,...{
          cod_cliente:cliente.cli_id
          , api_token_carrito: cliente.api_token_carrito
        }
        ,...{
          incremento: incremento
        }
      };
      
      return new Promise((resolve, reject) => {
        vm.$http.post('tiendaVirtual/actualizar_carrito',objectToFormData(post_object)).then((res)=>{
          resolve(res.data);
        }).catch(error => {
          console.log(error);
          reject(error);
        }).finally(()=>{
          commit('setLoading', false);
        });
      });
    },
    borrarProductoCarritoCliente({ commit }, {vm, producto, cliente}){
      commit('setLoading', true);
      let post_object = {
         ...{
          cod_producto: producto.prod_id
        }
        ,...{
          cod_cliente:cliente.cli_id
          , api_token_carrito: cliente.api_token_carrito
        }
      };
      return new Promise((resolve, reject) => {
        vm.$http.post('tiendaVirtual/borrar_producto_carrito',objectToFormData(post_object)).then((res)=>{
          resolve(res.data);
        }).catch(error => {
          reject(error);
        }).finally(()=>{
          commit('setLoading', false);
        });
      });
    }, 
  },
  modules: {
  }
})