export default{
    methods:{
        isNumber(e) {
            let key = e.keyCode || e.which;
            let keyString = String.fromCharCode(key).toLowerCase();
            let letters = "0123456789";
            let specials = [8, 9, 37, 39, 46, 44];
            let keySpecial = false;
            for (let i in specials) {
                if (key == specials[i]) {
                    keySpecial = true;
                    break;
                }
            }
            if (letters.indexOf(keyString) == -1 && !keySpecial) {
                e.preventDefault();
                return false;
            }
        },
        isNumberInteger(e, especiales) {
            let key = e.keyCode || e.which;
            let keyString = String.fromCharCode(key).toLowerCase();
            let letters = "0123456789";
            let specials = [];
            let keySpecial = false;
            for (let i in specials) {
                if (key == specials[i]) {
                    keySpecial = true;
                    break;
                }
            }
            if (letters.indexOf(keyString) == -1 && !keySpecial) {
                e.preventDefault();
                return false;
            }
        },
        isLetter(e) {
            let key = e.keyCode || e.which;
            let keyString = String.fromCharCode(key).toLowerCase();
            let letters = "ABCDEFGHIJKLMÑNOPQRSTUVWXYZabcdefghijklmnñopqrstuvwxyz";
            let specials = [32];
            let keySpecial = false;
            for (let i in specials) {
                if (key == specials[i]) {
                    keySpecial = true;
                    break;
                }
            }
            if (letters.indexOf(keyString) == -1 && !keySpecial) {
                e.preventDefault();
                return false;
            }
        },isEmail(e) {
          let key = e.keyCode || e.which;
            let keyString = String.fromCharCode(key).toLowerCase();
            let letters = "ABCDEFGHIJKLMÑNOPQRSTUVWXYZabcdefghijklmnñopqrstuvwxyz@_*0123456.-+";
            let specials = [32];
            let keySpecial = false;
            for (let i in specials) {
                if (key == specials[i]) {
                    keySpecial = true;
                    break;
                }
            }
            if (letters.indexOf(keyString) == -1 && !keySpecial) {
                e.preventDefault();
                return false;
            }
        },
        isEmpty(value){
            if(value==undefined || value==null || value==''){
                return true;
            }
            if (this.isObject(value)) {
                return !Object.keys(value).length
            }
            return false;
        },
        isObject(o) {
            return o instanceof Object && o.constructor === Object;
        },
        fecha_actual_es(){
            var f = new Date();

            return f.getFullYear() + "-"+ this.str_pad(f.getMonth() + 1, 2,'0', 'STR_PAD_LEFT')+ "-"+this.str_pad(f.getDate(), 2, '0', 'STR_PAD_LEFT');
        },
        validEmail: function (email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
        },
        str_pad (input, padLength, padString, padType) {
              let half = ''
              let padToGo
              const _strPadRepeater = function (s, len) {
                let collect = ''
                while (collect.length < len) {
                  collect += s
                }
                collect = collect.substr(0, len)
                return collect
              }
              input += ''
              padString = padString !== undefined ? padString : ' '
              if (padType !== 'STR_PAD_LEFT' && padType !== 'STR_PAD_RIGHT' && padType !== 'STR_PAD_BOTH') {
                padType = 'STR_PAD_RIGHT'
              }
              if ((padToGo = padLength - input.length) > 0) {
                if (padType === 'STR_PAD_LEFT') {
                  input = _strPadRepeater(padString, padToGo) + input
                } else if (padType === 'STR_PAD_RIGHT') {
                  input = input + _strPadRepeater(padString, padToGo)
                } else if (padType === 'STR_PAD_BOTH') {
                  half = _strPadRepeater(padString, Math.ceil(padToGo / 2))
                  input = half + input + half
                  input = input.substr(0, padLength)
                }
              }
              return input
        },
        number_format(number, decimals, dec_point, thousands_sep){
          number = (number + '')
            .replace(/[^0-9+\-Ee.]/g, '');
          var n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            s = '',
            toFixedFix = function(n, prec) {
              var k = Math.pow(10, prec);
              return '' + (Math.round(n * k) / k)
                .toFixed(prec);
            };
          // Fix for IE parseFloat(0.55).toFixed(0) = 0;
          s = (prec ? toFixedFix(n, prec) : '' + Math.round(n))
            .split('.');
          if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
          }
          if ((s[1] || '')
            .length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1)
              .join('0');
          }
          return s.join(dec);
        },
    },
    data: function(){
        return {
            fixed: 2,
        }
    },
  }