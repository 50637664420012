import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios';
//import GAuth from 'vue-google-oauth2';
import mixins from './mixins';

window.baseUrl  = process.env.VUE_APP_ROOT_API;
window.tokenAPI = process.env.VUE_APP_TOKEN_API;//Para mi token API
window.baseCli  = process.env.VUE_APP_ROOT_CLI;
window.tokenCulqui = process.env.VUE_APP_TOKEN_CULQI;

const instance  = axios.create({
  baseURL: window.baseUrl,
  headers: {
    Authorization: "Bearer "+window.tokenAPI
  }
});

/*
const gauthOption = {
  clientId: '402042152092-569dap3jp571c2qiauvfpnf7ki0ik3bh.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'consent',
  fetch_basic_profile: false
}
*/

window.$ = window.jQuery = require('./static/js/jquery.min');
window.Popper     = require('./static/js/popper.min');
window.bootstrap  = require('./static/js/bootstrap.min');
window.carousel   = require('./static/plugins/owl-carousel/owl.carousel');
window.toastr     = require('toastr');

Vue.mixin(mixins);
//Vue.use(GAuth, gauthOption);
Vue.config.productionTip = false
Vue.prototype.$http=instance;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
