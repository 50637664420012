<template>
    <div class="toolbar_main">
        <!-- SLIDER PRINCIPAL -->
        <div v-if="Slider_principal.length>0" id="slider_principal">
            <div class="item" v-for="(value, key) in Slider_principal" :key="key" >
                <a href="javascript:void(0)" class="cursor-default">
                    <img class="d-block img-fluid" :src="value.url_imagen" alt="">
                </a>
            </div>
        </div>

        <section v-if="!sucursal_seleccionada" class="text-center">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <p class="text-center fw-bold  " style="font-size: 1.5rem;color:black;">Bienvenido a tienda virtual "{{empresa.razon_social}}"</p>
                        <p class="text-center mb-1 " style="font-size: 1rem;margin-bottom: 1rem;">Estamos cargando la información necesaria para que puedas ver nuestros productos...</p>
                        <img style="text-align: center;margin-top: 1rem;width: 5rem;" class="mb-3" src="/img/actualizado.png">
                    </div>
                </div>
            </div>
        </section>

        <!-- OFERTAS -->
        <section v-if="productos_oferta.length>0 && sucursal_seleccionada" class="featured-products">
            <div class="container">
                <div class="section-header">
                    <h5 class="heading-design-h5" style="color:black">Ofertas</h5>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div id="owl-carousel-featured" class="owl-carousel owl-carousel-featured">
                            <div v-for="(value, key) in productos_oferta" :key="key" class="item">
                                <div class="h-100">
                                    <div class="product-item pro">
                                        <span class="badge badge-primary offer-badge">{{value.descuento_web}}% OFF</span>
                                        <div class="product-item-image">
                                            <a :href="'/producto/'+sucursal_seleccionada+'/'+value.prod_id" data-toggle="tooltip" data-placement="top" :data-original-title="value.nombre_producto">
                                                <img class="card-img-top img-fluid" :src="value.url_imagen" :alt="value.nombre_producto">
                                            </a>
                                        </div>
                                        <div class="product-item-body">
                                            <div class="product-item-action">
                                                <a v-if="modo_presentacion=='StoreOnline'" data-toggle="tooltip" data-placement="top" title="Agregar a Carrito" class="btn btn-theme-round btn-sm mr-3" href="javascript:void(0);" @click="agregarCarrito(value, 1, false)">
                                                    <i class="icofont icofont-shopping-cart"></i>
                                                </a>
                                                <a data-toggle="tooltip" data-placement="top" title="Ver Producto" class="btn btn-theme-round btn-sm" :href="'/producto/'+sucursal_seleccionada+'/'+value.prod_id">
                                                    <i class="icofont icofont-eye"></i>
                                                </a>
                                            </div>
                                            <h4 class="card-title text-truncate"><a :href="'/producto/'+sucursal_seleccionada+'/'+value.prod_id">{{value.nombre_producto}}</a></h4>
                                            <h5>
                                                <span class="product-desc-price">S/{{number_format(value.precio_venta, 2)}}</span>
                                                <span class="ml-1 product-price">S/{{number_format(value.precio_venta_real, 2)}}</span>
                                                <span class="ml-1 product-discount">{{value.descuento_web}}% Off</span>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- CARTERA PRODUCTOS -->
        <section v-if="sucursal_seleccionada && productos_tienda.length>0" class="categories-list">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <h5 class="heading-design-h5">
                            Elementos destacados 
                            <a :href="'/catalogo/'+sucursal_seleccionada" class="btn btn-warning pull-right">
                                Ver mas productos <b>{{total_productos}}</b> <i class="fa fa-arrow-right"></i>
                            </a>
                        </h5>

                        <div class="row">
                            <div class="col-lg-3 mb-5 col-md-3 col-sm-6" v-for="(value, key) in productos_tienda" :key="key" >
                                <div class="h-100">
                                    <div class="product-item">
                                        <div class="product-item-image">
                                            <a :href="'/producto/'+sucursal_seleccionada+'/'+value.prod_id" data-toggle="tooltip" data-placement="top" :data-original-title="value.nombre_producto">
                                                <img class="card-img-top img-fluid" :src="value.url_imagen" :alt="value.nombre_producto">
                                            </a>
                                        </div>

                                        <div class="product-item-body">
                                            <div class="product-item-action">
                                                <a v-if="modo_presentacion=='StoreOnline'" data-toggle="tooltip" data-placement="top" title class="btn btn-theme-round btn-sm mr-3" href="javascript:void(0);" @click="agregarCarrito(value, 1, false)" data-original-title="Agregar a Carrito">
                                                    <i class="icofont icofont-shopping-cart"></i>
                                                </a>

                                                <a data-toggle="tooltip" data-placement="top" title class="btn btn-theme-round btn-sm" :href="'/producto/'+sucursal_seleccionada+'/'+value.prod_id" data-original-title="Ver Producto">
                                                    <i class="icofont icofont-eye"></i>
                                                </a>
                                            </div>

                                            <h4 class="card-title text-truncate"><a :href="'/producto/'+sucursal_seleccionada+'/'+value.prod_id">{{value.nombre_producto}}</a></h4>
                                            <h5>
                                                <span class="ml-1 product-price">S/{{number_format(value.precio_venta_real, 2)}}</span>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section v-else class="text-center">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <h5 class="heading-design-h5">Inventario en proceso</h5>

                        <p class="text-center fw-bold" style="font-size: 1.5rem;color:black;">Bienvenido a tienda virtual "{{empresa.razon_social}}"</p>
                        <p class="text-center mb-1" style="font-size: 1rem;margin-bottom: 1rem;">Sentimos las molestias, estamos configurando el inventario para mostrar los productos mas novedosos del mercado tecnológico</p>
                        <i class="fa fa-list-ol" style="font-size:80px" aria-hidden="true"></i>
                        <p class="text-center mb-1 " style="font-size: 1rem;margin-bottom: 1rem;">Inventario en proceso, disculpe las molestias</p>
                    </div>
                </div>
            </div>
        </section>

        <!-- TOP MAS VENDIDOS -->
        <section v-if="top_vendidos.length>0 && sucursal_seleccionada" class="hot-offers">
            <div class="container">
                <div class="section-header">
                    <h5 class="heading-design-h5">Top <span id="countdown">4</span> Más vendidos</h5>
                </div>

                <div class="row">
                    <div v-for="(value, key) in top_vendidos" :key="key" class="col-lg-3 col-md-3 mb-2 col-sm-6">
                        <div class="h-100">
                            <div class="product-item">
                                <div class="product-item-image">
                                    <a :href="'/producto/'+sucursal_seleccionada+'/'+value.prod_id">
                                        <img class="card-img-top img-fluid" :src="value.url_imagen" :alt="value.nombre_producto">
                                    </a>
                                </div>

                                <div class="product-item-body">
                                    <h4 class="card-title text-truncate">
                                        <a :href="'/producto/'+sucursal_seleccionada+'/'+value.prod_id">{{value.nombre_producto}}</a>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- MARCAS RECONOCIDAS -->
        <section v-if="sucursal_seleccionada && marcas.length>0" id="marcas_reconocidas" class="">
            <div class="container">
                <div class="section-header">
                    <h5 class="heading-design-h5">Marcas reconocidas</h5>
                </div>
                <div class="row text-center">
                    <div v-for="(value, key) in marcas" :key="key" class="col-lg-2 col-md-2 col-sm-2">
                        <img class="img-responsive" :src="value.url_logo" :alt="value.marca" :title="value.marca">
                    </div>
                </div>
            </div>
        </section>

        <!-- SERVICIOS BRINDADOS -->
        <section class="hot-offers text-center">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <p class="text-center fw-bold" style="font-size: 1.5rem;color:black;">Queremos darte lo mejor</p>
                        <p class="text-center mb-1 " style="font-size: 1rem;margin-bottom: 1rem;" > Disfruta de una mejor experienca de compra. Conoce algunos de los beneficos que tenemos para ti.</p> 
                        <br>
                        <div class="row text-center">
                            <div class="col-lg-2 col-md-2 col-sm-2">
                                <div class="card shadow rounded mb-2 card-cursor" style="background-color: #f5f5f5;border: 0;" >
                                    <div class="card-body" style="padding-bottom: 1px; padding-left:3px;padding-right: 3px;" >
                                        <p> <i class="fa  fa-map-o fa-2x"></i> </p> 
                                        <p style="color: black;" >Tracking Pedido</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-2 col-md-2 col-sm-2">
                                <div class="card shadow rounded mb-2 card-cursor" style="background-color: #f5f5f5;border: 0;" >
                                    <div class="card-body" style="padding-bottom: 1px; padding-left:3px;padding-right: 3px;" >
                                        <p> <i class="fa  fa-home fa-2x"></i> </p> 
                                        <p style="color: black;" >Retiro gratis</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-2 col-md-2 col-sm-2">
                                <div class="card shadow rounded mb-2 card-cursor" style="background-color: #f5f5f5;border: 0;" >
                                    <div class="card-body" style="padding-bottom: 1px; padding-left:3px;padding-right: 3px;" >
                                        <p> <i class="fa  fa-percent  fa-2x"></i> </p> 
                                        <p style="color: black;" >Promociones</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-2 col-md-2 col-sm-2">
                                <div class="card shadow rounded mb-2 card-cursor" style="background-color: #f5f5f5;border: 0;" >
                                    <div class="card-body" style="padding-bottom: 1px; padding-left:3px;padding-right: 3px;" >
                                        <p> <i class="fa fa-cart-plus fa-2x"></i> </p> 
                                        <p style="color: black;" >Compra fácil y seguro </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-2 col-md-2 col-sm-2">
                                <div class="card shadow rounded mb-2 card-cursor" style="background-color: #f5f5f5;border: 0;" >
                                    <div class="card-body" style="padding-bottom: 1px; padding-left:3px;padding-right: 3px;" >
                                        <p > <i class="fa fa-credit-card  fa-2x"></i> </p> 
                                        <p style="color: black;" >Métodos de pago </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-2 col-md-2 col-sm-2">
                                <div class="card shadow rounded mb-2 card-cursor" style="background-color: #f5f5f5;border: 0;" >
                                    <div class="card-body" style="padding-bottom: 1px; padding-left:3px;padding-right: 3px;" >
                                        <p> <i class="fa fa-phone fa-2x"></i> </p> 
                                        <p style="color: black;" >Contáctenos</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- MODAL SLIDER FLOTANTE -->
        <Modal ref="form_emergente" dialog="modal-md">
			<template slot="title"></template>
			<form :class="['modal-body', 'needs-validation', 'was-validated']" novalidate>
				<div :class="['']">
					<div :class="['row']">
						<div :class="['col-lg-12', 'col-12', 'form-group']">
                            <div :class="['owl-carousel', 'owl-theme']" id="owl_flotante">
                                <div v-for="(val, key) in Slider_flotante" :key="key" :class="[]" >
                                    <div class="item">
                                        <div class="car-container">
                                            <img :src="val.url_imagen" alt="" class="img-responsive">
                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>
					</div>
				</div>
			</form>
		</Modal>

        <Login ref="form_login" :title_modal="'¡Hola!, Para comprar, ingresa a tu cuenta'"></Login>
    </div>
</template>

<script>
    import {mapState, mapMutations, mapActions} from 'vuex';
    import Modal from '@/components/Modal.vue';
    import Login from '@/components/Login.vue';

    export default {
        name: 'Welcome',
        computed:{
            ...mapState(['empresa', 'mi_cuenta', 'sucursal_seleccionada']),
        },
        components: {
            Modal,
            Login,
		},
        created(){
            this.loadObjEmpresa();
            this.loadObjSucursalSeleccionada();
            this.loadObjPerfilUsuario();
        },
        mounted(){
            this.setLoading(true);
            
            this.getPrincipal();
            
            document.title = "Bienvenido a "+((this.empresa.abreviatura)?this.empresa.abreviatura:"-");
        },
        data(){
            return{
                Slider_principal: [],
                Slider_flotante:[],
                productos_oferta:[],
                productos_tienda:[],
                top_vendidos:[],
                marcas:[],
                total_productos: 0,
                modo_presentacion: process.env.VUE_APP_MODE,
            }
        },
        methods:{
            ...mapMutations(['setLoading']),
            ...mapActions(['loadObjEmpresa', 'loadObjPerfilUsuario', 'loadObjSucursalSeleccionada']),
            getPrincipal(){
                var selfs = this;
            
                this.$http.get('tiendaVirtual/principal?sucursal='+this.sucursal_seleccionada).then((res)=>{
                    if(res.data.Slider_principal)
                        selfs.Slider_principal      = res.data.Slider_principal;
                    if(res.data.Slider_flotante)
                        selfs.Slider_flotante       = res.data.Slider_flotante;
                    if(res.data.productos_ofertas)
                        selfs.productos_oferta      = res.data.productos_ofertas;
                    if(res.data.productos_general)
                        selfs.productos_tienda      = res.data.productos_general;
                    if(res.data.top_vendidos)
                        selfs.top_vendidos          = res.data.top_vendidos;
                    if(res.data.marcas_top)
                        selfs.marcas                = res.data.marcas_top;

                    selfs.total_productos           = res.data.total_productos;

                    if(this.Slider_flotante.length>0){
                        this.$refs.form_emergente.open();
                    }

                    setTimeout(()=>{
                        this.owlCarousel();
                        $('[data-toggle="tooltip"]').tooltip();
                    },300);
                }).finally(()=>{
                    setTimeout(()=>{
                        this.setLoading(false);
                    },1000);
                });
            },
            agregarCarrito(producto, cantidad, reemplazar_cantidad){
                if(!this.$store.state.mi_cuenta.cli_id){
                    this.$refs.form_login.abrir_login();
                    return;
                }

                if(producto.stock>=cantidad && producto.stock>0){
                    this.$store.commit('agregarCarrito',{
                        producto:producto,
                        cantidad:cantidad,
                        reemplazar_cantidad: reemplazar_cantidad,
                        sucursal: this.sucursal_seleccionada,
                        incremento: true,
                    });
                }else{
                    toastr.warning('No hay stock disponible para este producto', 'Mensaje');
                }
            },
            owlCarousel(){
                $('#slider_principal').owlCarousel({ lazyLoad: true, pagination: false, autoPlay: 4000, singleItem: true, navigation: true, stopOnHover: true });
                $('#owl-carousel-featured').owlCarousel({ lazyLoad: true, pagination: false, autoPlay: 2000, navigation: true, stopOnHover: true });
                $('#owl_flotante').owlCarousel({ items:1, lazyLoad: true, pagination: false, autoPlay: 2000, navigation: true, stopOnHover: true, responsiveClass: true});
            },
            cerrarModal(){
                var selfs = this;
				selfs.$refs.form_emergente.close();
            },
        },
    }
</script>