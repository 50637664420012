<template>
    <Web/>
</template>
<script>
    import Web from '@/components/Welcome.vue';
    export default {
        name:'tienda',
        components:{
            Web
        }
    }
</script>