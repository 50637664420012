<template>
    <div>
        <div id="loading" v-if="loading">
            <div class="load-circle" style="text-align: center;">
                <div style="text-align: center;">
                    <img :src="empresa.logo" style="text-align: center;" class="img-fluid">
                </div>
                <div style=" ">        
                    <span class="loader"></span>
                    <span class="loader"></span>
                    <span class="loader"></span>
                </div>
            </div>
        </div>

        <nav class="navbar navbar-light navbar-expand-lg bg-primary bg-faded osahan-menu osahan-menu-top-3 sticky-topv2">
            <div class="container">
                <a class="navbar-brand" :href="'/tienda/'+$store.state.sucursal_seleccionada">
                    <img :src="empresa.logo" style="width:131px; height:34px;" alt="logo">
                </a>
                <button class="navbar-toggler navbar-toggler-white" type="button" data-toggle="collapse" 
                    data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                    <div class="navbar-nav mr-auto mt-2 mt-lg-0 margin-auto top-categories-search-main">
                        <div class="top-categories-search">
                            <div class="input-group mb-1 mt-1">
                                <input class="form-control" placeholder="Buscar productos" v-model="$store.state.producto_filtro" type="text" @keyup.enter="buscarTienda">
                                <span class="input-group-btn">
                                    <button class="btn btn-dark cursor-pointer" @click="buscarTienda" type="button"><i class="icofont icofont-search-alt-2"></i> Buscar</button>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div v-if="modo_presentacion=='StoreOnline'" class="my-2 my-lg-0 d-none">
                        <ul class="list-inline main-nav-right">
                            <li class="list-inline-item dropdown osahan-top-dropdown">
                                <a class="btn btn-danger dropdown-toggle" :href="'/carrito/'+$store.state.sucursal_seleccionada" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="icofont icofont-shopping-cart"></i> Carrito <small class="cart-value">{{$store.state.carrito.length}}</small>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="my-2 my-lg-0">
                        <ul class="list-inline main-nav-right">
                            <li class="list-inline-item dropdown" id="sucu">
                                <select class="form-control" style="line-height: 1.5;height: auto;padding-bottom: 8px; font-size:10.5px;" v-model="$store.state.sucursal_seleccionada" ref="sucursal" @change="cambiarSucursal">
                                    <option v-for="(val, key) in sucursales" :key="key" :value="val.id">
                                        {{val.sucursal}}
                                    </option>
                                </select>
                            </li>

                            <li class="list-inline-item dropdown osahan-top-dropdown" id="cartm">
                                <a v-if="modo_presentacion=='StoreOnline'" :class="['btn', 'btn-danger', $store.state.carrito.length>0?'dropdown-toggle':'']" style="font-size:10.5px;" :href="'/carrito/'+$store.state.sucursal_seleccionada" :data-toggle="($store.state.carrito.length>0)?'dropdown':''" id="acartm">
                                    <i class="icofont icofont-shopping-cart"></i> Carrito 
                                    <small v-if="$store.state.carrito.length>0" class="cart-value">{{$store.state.carrito.length}}</small>
                                </a>
                                
                                <div v-if="$store.state.carrito.length>0" class="dropdown-menu dropdown-menu-right cart-dropdown" id="styleOver" style="max-height: 18rem; overflow-y:auto; overflow-x:hidden">
                                    <div v-for="(val, key) in $store.state.carrito" :key="key" class="dropdown-item">
                                        <a class="pull-right" @click.prevent="borrarItemCarrito(val)" data-toggle="tooltip" data-placement="top" title href="javascript:void(0);" data-original-title="Borrar">
                                            <i class="fa fa-trash-o"></i>
                                        </a>
                                        <a :href="'/producto/'+$store.state.sucursal_seleccionada+'/'+val.prod_id">
                                            <img class="img-fluid" :src="val.url_imagen" style="height:48px;" :alt="val.nombre_producto" />
                                            <strong class="text-truncate" style="font-size:10px;">{{cortar_nombre_producto(val.nombre_producto)}}</strong>
                                            <small>SKU : {{val.cod_articulo}}</small>
                                            <span class="product-price text-danger">S/ {{number_format(val.precio_venta, 2, '.', ',')}}</span>
                                            <span class="text-muted"> x {{val.cantidad}}</span>
                                        </a>
                                    </div>

                                    <div class="dropdown-divider"></div>
                                    <div class="dropdown-cart-footer text-center">
                                        <h4> <strong>Subtotal</strong>: S/ {{number_format(totalVenta, 2)}} </h4>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <a class="btn btn-sm btn-danger" style="width:90%;" :href="'/carrito/'+$store.state.sucursal_seleccionada">
                                                    <i class="icofont icofont-shopping-cart"></i> VER CARRITO / PROCESAR
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            
                            <li v-if="!$store.state.mi_cuenta.cli_id" class="list-inline-item usulogin">
                                <a v-if="modo_presentacion=='StoreOnline'" class="btn btn-danger" id="ausulogin" style="font-size:10.5px;" href="javascript:void(0);" @click="$refs.form_login.abrir_login()">
                                    <i class="icofont icofont-ui-user"></i> Iniciar sesión
                                </a>
                            </li>

                            <li v-else class="list-inline-item dropdown usulogin osahan-top-dropdown">
                                <a class="btn btn-danger dropdown-toggle" style="font-size:10.5px;" id="ausuperfil" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="icofont icofont-ui-user"></i> {{$store.state.mi_cuenta.first_name}}
                                </a>
                                <div class="dropdown-menu dropdown-menu-left">
                                    <div class="dropdown-item">                  
                                        <a href="/perfil"> <i class="fa fa-user"></i> Mi perfil</a>
                                    </div>

                                    <div class="dropdown-item">
                                        <a :href="'/carrito/'+$store.state.sucursal_seleccionada"> <i class="fa fa-cart-arrow-down"></i> Ir a carrito/ Procesar</a>
                                    </div>
                                    
                                    <div class="dropdown-item">
                                        <a href="/seguimiento_pedido"> <i class="fa fa-map-marker"></i> Seguimiento de pedido</a>
                                    </div>

                                    <div class="dropdown-item">
                                        <a :href="'/catalogo/'+$store.state.sucursal_seleccionada"> <i class="icofont icofont-shopping-cart"></i> Seguir comprando</a>
                                    </div>

                                    <div class="dropdown-divider"></div>
                                    <div class="dropdown-cart-footer text-center">
                                        <a class="btn btn-sm btn-danger w-75" @click="cerrar_sesion" href="javascript:void(0);">
                                            <i class="fa fa-sign-out"></i>
                                            Salir 
                                        </a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>

        <!-- MODAL LOGIN -->
        <Login ref="form_login"></Login>
    </div>
</template> 

<script>
    import {mapState, mapMutations, mapActions} from 'vuex';
    import Login from '@/components/Login.vue';

    export default {
        name:'ToolBar',
        computed:{
            ...mapState(['loading','empresa', 'sucursales', 'mi_cuenta', 'sucursal_seleccionada']),
            totalVenta() {
                let total = 0;

                for (let item of this.$store.state.carrito) {
                    total += item.precio_venta*item.cantidad;
                }
                
                return total.toFixed(2);
            }
        },
        components: {
            Login,
		},
        created(){
            this.loadObjCarrito();
            this.loadObjSucursales();
            this.loadObjPerfilUsuario();
            this.loadObjSucursalSeleccionada();
        },
        mounted(){
            if(this.$route.query && this.$route.query.filter_cat)
                this.setFiltroCategoria(this.$route.query.filter_cat.split(','));

            if(this.$route.query && this.$route.query.filter_marc)
                this.setFiltroMarca(this.$route.query.filter_marc.split(','));

            if(this.$route.query && this.$route.query.orderItem)
                this.setFiltroOrderItem(this.$route.query.orderItem);

            if(this.$route.query && this.$route.query.orderType)
                this.setFiltroOrderType(this.$route.query.orderType);

            if(this.$route.query && this.$route.query.filter_product)
                this.setFiltroProducto(this.$route.query.filter_product);
        },
        data(){
            return{
                formLogin:{
                    nick:"",
                    clave:"",
                    msg:"",
                    status: false,
                },
                errorLogin:{
                    nick:"",
                    clave:"",
                },
                pag: (this.$route.query.pag)?this.$route.query.pag:1,
                orderItem: (this.$route.query.orderItem)?this.$route.query.orderItem:"oferta",
                orderType: (this.$route.query.orderType)?this.$route.query.orderType:"desc",
                id_sucursal: this.$route.params.sucursal??this.sucursal_seleccionada,
                modo_presentacion: process.env.VUE_APP_MODE,
            }
        },
        methods:{
            ...mapMutations(['setLoading', 'setFiltroOrderItem', 'setFiltroOrderType', 'setFiltroCategoria', 'setFiltroMarca', 'setFiltroProducto', 'setSucursalSeleccionada']),
            ...mapActions(['loadObjPerfilUsuario', 'loadObjSucursalSeleccionada', 'loadObjSucursales', 'loadObjCarrito']),
            cerrar_sesion(){
                this.$store.commit('borrarSesion');
                this.setLoading(true);

                setTimeout(()=>{
                    location.href = window.location.origin+"/catalogo/"+this.sucursal_seleccionada;
                }, 1000);
            },
            borrarItemCarrito(item) {
                let this_ = this;
                this_.setLoading(true);

                this_.$store.commit('borrarItemCarrito', item);
                setTimeout(()=>{
                    this_.setLoading(false);
                }, 1000);
            },
            buscarTienda(){
                this.setLoading(true);

                let filter_categoria    = this.$store.state.categoria_filtro;
                let filter_marca        = this.$store.state.marca_filtro;
                let filter_producto     = this.$store.state.producto_filtro;
                let filter_sucursal     = this.$store.state.sucursal_seleccionada;
                
                let get_str = "";
                get_str+= "pag="+this.pag;
                if(filter_producto)
                    get_str+="&filter_product="+filter_producto;
                if(this.orderItem)
                    get_str+= "&orderItem="+this.orderItem;
                if(this.orderType)
                    get_str+= "&orderType="+this.orderType;
                if(filter_categoria.length)
                    get_str+= "&filter_cat="+filter_categoria;
                if(filter_marca.length)
                    get_str+= "&filter_marc="+filter_marca;
                
                location.href = '/catalogo/'+filter_sucursal+'?'+get_str;
            },
            cambiarSucursal(){
                let path_with_sucursal = ["tienda", "catalogo", "carrito", "directorio_asesores", "producto"];
                let filter_sucursal     = this.$refs.sucursal.value;

                this.setSucursalSeleccionada(this.$refs.sucursal.value);
                console.log("change: ",this.$route);
                
                if( path_with_sucursal.indexOf(this.$route.name)==-1){
                    console.log("Está en "+this.$route.name+", solo vamos hacer reload");
                    //return;
                }else{
                    if(this.$route.name=="catalogo"){
                        console.log("Catalogo con sus parametros: "+this.$route.name+"");
                        //this.buscarTienda();
                        //return;
                    }else{
                        console.log("redireccionamos a:", "/"+this.$route.name+"/"+filter_sucursal);
                        //return;
                    }
                    
                }
            },
            cortar_nombre_producto(texto){
                let nombre_producto_ = "";

                if(texto){
                    if(texto.length>26){
                        nombre_producto_ = texto.substr(1, 26)+"...";
                    }else{
                        nombre_producto_ = texto;
                    }
                }

                return nombre_producto_;
            },
        }
    }
</script>