<template>
    <div class="modal fade vue-modal" :id="idModal" tabindex="-1" role="dialog" aria-labelledby="dlgConLabel" aria-hidden="true">
        <div class="modal-dialog " :class="[dialog]">
            <div class="modal-content">
                <!--
                <button type="button" class="close close-top-right" style="margin-right: 40px;cursor: pointer;top: 10px; border-radius: 50%;" data-dismiss="modal" aria-label="Close" @click="close">
                    <span aria-hidden="true">×</span>
                    <span class="sr-only">Close</span>
                </button>
                -->
                <div class="modal-header bg_color_drasam">
                    <h4 class="modal-title">
                        <slot name="title"></slot>
                    </h4>
                    
                    <button type="button" class="btn cursor rounded-circle" style="color:white; background:black; cursor: pointer" aria-label="Close" @click="close">
                        <span aria-hidden="true">×</span>
                        <span class="sr-only">Close</span>
                    </button>
                </div>
                
                <slot></slot>
                <!--
                <div class="modal-footer">
                    <slot name="footer"></slot>
                </div>
                -->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Modal",
        props: {
            dialog: {
                default: ''
            },
        },
        mounted() {
            const dlgModal = $(this.$el);
            if(this.$el.hasAttribute('append-to-body')){
                document.body.appendChild(this.$el);
            }
            dlgModal.modal({backdrop: 'static', show: false, keyboard: false});
            dlgModal.on('hide.bs.modal', this.hide);
        },
        computed: {
            idModal() {
                return Math.floor(Math.random() * (999 - 100 + 1)) + 100 + '_modal';
            }
        },
        methods: {
            open() {
                $(this.$el).modal('show');
            },
            close() {
                $(this.$el).modal('hide');
            },
            hide() {
                this.$emit('hide')
            },
            removeTabIndex(){
                document.getElementById(this.idModal).removeAttribute("tabindex");
            },
        },
    }
</script>