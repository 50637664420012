import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'inicio',
    component: Dashboard
  },
  {
    path: '/tienda/:sucursal',
    name: 'tienda',
    component: Dashboard
  },
  {
    path: '/catalogo/:sucursal',
    name: 'catalogo',
    component: () => import('../views/Catalogo.vue')
  },
  {
    path: '/producto/:sucursal/:slug',
    name: 'producto',
    component: () => import('../views/Producto.vue')
  },
  {
    path: '/carrito/:sucursal',
    name: 'carrito',
    component: () => import('../views/Carrito.vue')
  },
  {
    path: '/directorio_asesores/:sucursal',
    name: 'directorio_asesores',
    component: () => import('../views/Asesores.vue')
  },
  {
    path: '/sucursales',
    name: 'sucursales',
    component: () => import('../views/Sucursales.vue')
  },
  {
    path: '/seguimiento_pedido',
    name: 'seguimiento_pedido',
    component: () => import('../views/SeguimientoPedido.vue')
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: () => import('../views/Perfil.vue')
  },
  {
    path: '/recuperar_clave',
    name: 'recuperarClave',
    component: () => import('../views/RecuperarClave.vue')
  },
  {
    path: '/recuperar_cuenta',
    name: 'recuperarCuenta',
    component: () => import('../views/RecuperarCuenta.vue')
  },
  {
    path: '/politicas',
    name: 'politicas',
    component: () => import('../views/Politicas.vue')
  },
  {
    path: '/consulta_comprobante',
    name: 'consulta_comprobante',
    component: () => import('../views/ConsultaComprobante.vue')
  },
  {
    path: '/download/:codigo_unico',
    name: 'Download',
    component: () => import('../views/Descarga.vue')
  },
  {
    path: "*",
    name: "NotFound",
    property: {},
    children: [],
   component: () => import('../views/Error404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
