<template>
    <div>
        <footer>
            <section class="footer-Content">
                <div class="container">
                    <div class="row">    
                        <div class="col-lg-3 col-md-3 col-sm-3">
                            <div class="footer-widget">
                                <h3 class="block-title mb-3">Nuestra Compañía</h3>
                                <ul class="menu " style="height: 80px;">
                                    <li><a href="/sucursales">Sucursales</a></li>
                                    <li><a :href="'/directorio_asesores/'+sucursal_seleccionada">Asesores de venta</a></li>
                                    <li>RUC <b>{{empresa.ruc}}</b></li>
                                </ul>
                    
                                <b class="text-white" style="margin-top: 0.9rem;">Metodos de pago </b>
                                <div>
                                    <img src="/img/pay/m1.png" style="max-width: 60px;margin-bottom: 4px;" >
                                    <img src="/img/pay/m2.png" style="max-width: 60px;margin-bottom: 4px;" > 
                                    <img src="/img/pay/m3.png" style="max-width: 60px;margin-bottom: 4px;" > 
                                    <img src="/img/pay/m4.png" style="max-width: 60px;margin-bottom: 4px;" >                  
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-lg-3 col-md-3 col-sm-3">
                            <div class="footer-widget">
                                <h3 class="block-title mb-3 ">Acceso rápido</h3>
                                <ul class="menu">
                                    <li><a :href="'/tienda/'+sucursal_seleccionada">Inicio</a></li>
                                    <li><a href="/seguimiento_pedido">Seguimiento de pedido</a></li>
                                    <li><a :href="'/catalogo/'+sucursal_seleccionada">Tienda</a></li>
                                    <li><a href="/consulta_comprobante">Consultar comprobante electrónico</a></li>
                                    <li><a href="/politicas">Términos y condiciones</a></li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-3 col-sm-3">
                            <div class="footer-widget">
                                <h3 class="block-title mb-3 " >Categorias</h3>
                                <ul class="menu">
                                    <li v-for="(item, key) in categorias" :key="key">
                                        <a :href="'/catalogo/'+sucursal_seleccionada+'?pag='+pag+'&orderItem='+orderItem+'&orderType='+orderType+'&filter_cat='+item.cat_id">{{ item.descripcion }}</a>
                                    </li>
                                    <li v-if="categorias.length==0">
                                        <i>Sin categorias disponibles :(</i>
                                    </li>
                                    <li v-if="categorias.length>5"><a :href="'/categorias/'+sucursal_seleccionada">Ver mas...</a></li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-3 col-sm-3">
                            <div class="footer-widget">
                                <h3 class="block-title mb-3 ">Atención al cliente</h3>
                                <div >
                                    <p v-if="empresa.telefono_movil"> 
                                        Telf. Móvil 
                                        <i class="fa fa-whatsapp mr-2"></i> 
                                        <a class="text-white" :href="'https://api.whatsapp.com/send?phone='+empresa.telefono_movil+'&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20de%20sus%20productos.'">{{empresa.telefono_movil}}</a>
                                    </p>
                                    <p v-else> Telf. Móvil <i>No definido</i> </p>
                                    
                                    <p>
                                        <i data-v-1ded0f20="" class="fa fa-envelope-o mr-2"></i> 
                                        <a class="text-white" href="/sucursales">{{empresa.email}}</a>
                                    </p>
                                    <hr>
                                    <a href="/seguimiento_pedido" class="btn btn-outline-light w-100 cursor-pointer">Seguimiento de pedido</a> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </footer>

        <section class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-md-8 col-sm-8">
                        <div class="footer-logo pull-left hidden-xs" style="min-width: 0px;">
                            <img alt :src="empresa.logo" style="width: 30px;" class="img-responsive">
                        </div>         
                        
                        <div class="copyright">
                            <p style="margin-top: 0.5rem;">© Copyright 2023  | &nbsp;Made in Perú</p>
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-md-4 col-sm-4 text-right">
                        <div class="social-icon">
                            <a v-for="(item, key) in redes_sociales" :key="key" :href="item.url" :alt="item.descripcion" target="_blank">
                                <i :class="['fa', item.icono]"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <button type="button" class="btn btn-danger rounded-circle cursor-pointer btn-floating btn-lg " @click="backToTop" id="btn-back-to-top">
            <i class="fa fa-arrow-up"></i>
        </button>

        <a v-if="empresa.telefono_movil" :href="'https://api.whatsapp.com/send?phone='+empresa.telefono_movil+'&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20de%20sus%20productos.'" class="float" target="_blank">
            <i class="fa fa-whatsapp my-float"></i>
        </a>
    </div>
</template> 

<script>
    import {mapState, mapMutations, mapActions} from 'vuex';
    
    export default {
        name:'FootBar',
        computed:{
            ...mapState(['empresa', 'redes_sociales', 'categorias', 'sucursales', 'sucursal_seleccionada']),
        },
        created(){
            this.loadObjCategoria();
            this.loadObjRedesSociales();
            this.loadObjSucursales();
            this.loadObjSucursalSeleccionada();
        },
        mounted(){
            if(this.$route.query && this.$route.query.filter_cat)
                this.setFiltroCategoria(this.$route.query.filter_cat.split(','));

            if(this.$route.query && this.$route.query.filter_marc)
                this.setFiltroMarca(this.$route.query.filter_marc.split(','));

            if(this.$route.query && this.$route.query.orderItem)
                this.setFiltroOrderItem(this.$route.query.orderItem);

            if(this.$route.query && this.$route.query.orderType)
                this.setFiltroOrderType(this.$route.query.orderType);

            if(this.$route.query && this.$route.query.filter_product)
                this.setFiltroProducto(this.$route.query.filter_product);

            this.iniciar_back_top();
        },
        data(){
            return{
                pag: (this.$route.query.pag)?this.$route.query.pag:1,
                orderItem: (this.$route.query.orderItem)?this.$route.query.orderItem:"oferta",
                orderType: (this.$route.query.orderType)?this.$route.query.orderType:"desc",
            }
        },
        methods:{
            ...mapMutations(['setLoading', 'setFiltroOrderItem', 'setFiltroOrderType', 'setFiltroCategoria', 'setFiltroMarca', 'setFiltroProducto']),
            ...mapActions(['loadObjSucursalSeleccionada', 'loadObjSucursales', 'loadObjRedesSociales', 'loadObjCategoria']),
            iniciar_back_top(){
                let mybutton = document.getElementById("btn-back-to-top");
                let self     = this;
                window.onscroll = function () {
                    self.scrollFunction(mybutton);
                };
            },
            scrollFunction(mybutton) {
                if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                    mybutton.style.display = "block";
                } else {
                    mybutton.style.display = "none";
                }
            },
            backToTop() {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            },
        }
    }
</script>

<style scoped>
    .menu li{
      width: 100% !important;
    }
    .btn-outline-light:hover {
        color: #000 !important;
    }

    .float {
        position: fixed;
        width: 50px;
        height: 50px;
        bottom: 20px;
        right: 20px;
        background-color: #25d366;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        font-size: 30px;
        box-shadow: 2px 2px 3px #999;
        z-index: 100;
    }

    .float:hover {
      color: #FFF
    }

    .my-float {
      margin-top: 10px;
    }
</style>