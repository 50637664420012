<template>
    <Modal ref="form_login" dialog="modal-lg">
        <div class="login-modal">
            <div class="row">
                <div class="col-lg-6 pad-right-0">
                    <div class="login-modal-left"></div>
                </div>
                
                <div class="col-lg-6 pad-left-0">
                    <form>
                        <div class="login-modal-right" style="padding: 20px 30px 30px 30px;">
                            <div class="tab-content">
                                <div class="tab-pane active" :id="'login'+version" role="tabpanel">
                                    <h5 class="heading-design-h5 text-center mb-4">{{title_modal}}</h5>
                                    <fieldset class="form-group">
                                        <strong class="required">Usuario</strong>
                                        <input type="text" class="form-control" v-model="formLogin.nick" ref="nick" placeholder="cliente@luatelclaro.pe">
                                        <span v-if="errorLogin.nick!=''" class="usuario text-danger" role="alert">{{errorLogin.nick}}</span>
                                    </fieldset>

                                    <fieldset class="form-group">
                                        <strong class="required">Clave</strong>
                                        <input type="password" class="form-control" v-model="formLogin.clave" ref="clave" placeholder="********">
                                        <span v-if="errorLogin.clave!=''" class="clave text-danger" style="display:block;" role="alert">{{errorLogin.clave}}</span>
                                        <span class="custom-control-description mt-0">
                                            ¿Olvidaste la clave? No te preocupes, haga clic <a class="fs-sm" target="_blank" href="/recuperar_clave">aquí</a>
                                        </span>
                                    </fieldset>

                                    <fieldset class="form-group mb-3">
                                        <button type="button" @click="iniciar_sesion" class="btn btn-lg btn-theme-round btn-block cursor-pointer">
                                            Ingresa a tu cuenta
                                        </button>

                                        <div class="alert mt-1 p-2" :class="[formLogin.status?'alert-success':'alert-danger']" v-if="formLogin.msg" role="alert">
                                            {{formLogin.msg}}
                                        </div>
                                    </fieldset>
                                </div>
                                
                                <div class="tab-pane" :id="'register'+version" role="tabpanel">
                                    <h5 class="heading-design-h5">REGISTRATE AHORA!</h5>
                                    <fieldset class="form-group">
                                        <strong class="required">Identificación</strong>
                                        <div class="input-group">
                                            <select class="form-control" v-model="formRegistro.tipo_cliente" @change="cambiar_tipo_documento_cliente">
                                                <option v-for="(val, key) in tipo_cliente" :key="key" :value="val.identificador">{{val.descripcion}}</option>
                                            </select>
                                            <input type="text" :class="['form-control', (formRegistro.numero_identificacion=='')?'border-danger':'']" v-model="formRegistro.numero_identificacion" ref="numero_identificacion_registro" :maxlength="formRegistro.long_tipo_doc_cliente" @keypress="isNumberInteger($event)" :placeholder="(formRegistro.tipo_documento_cliente=='DNI')?'00000001':'10000000010'" style="height: calc(2.25rem + 2px);">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" style="padding: 0.4rem 0.75rem;">{{formRegistro.tipo_documento_cliente}}</span>
                                            </div>
                                        </div>
                                        <span class="custom-control-description mt-0">
                                            ¿Número DNI registrado?, recupera tu cuenta <a class="fs-sm" target="_blank" href="/recuperar_cuenta">aquí</a>
                                        </span>
                                    </fieldset>

                                    <fieldset class="form-group">
                                        <strong class="required">Correo Electrónico</strong>
                                        <input type="email" :class="['form-control', (formRegistro.email=='')?'border-danger':'']" v-model="formRegistro.email" ref="email_registro" placeholder="cliente@luatelclaro.pe">
                                        <p>
                                            Por seguridad el sistema generará su clave, el cual será enviado al correo electronico ingresado.
                                        </p>
                                        <span class="custom-control-description mt-0">
                                            ¿Correo electrónico registrado?, recupera tu clave <a class="fs-sm" target="_blank" href="/recuperar_clave">aquí</a>.
                                        </span>
                                    </fieldset>
                                    
                                    <fieldset class="form-group mb-2" v-if="formRegistro.error.length>0">
                                        <ul class="list-group" style="height:40px; overflow-y:auto;">
                                            <li class="list-group-item list-group-item-action list-group-item-danger p-1" v-for="(val, key) in formRegistro.error" :key="key">
                                                {{val}}
                                            </li>
                                        </ul>
                                    </fieldset>

                                    <fieldset class="form-group">
                                        <button type="button" class="btn btn-lg btn-theme-round cursor-pointer btn-block" @click="registrar_cliente">
                                            Crear cuenta
                                        </button>
                                    </fieldset>
                                </div>
                            </div>

                            <div class="clearfix"></div>
                            <fieldset class="form-group mb-0">
                                <div class="alert mt-1 p-2 mb-2 alert-info">
                                    Los campos (<span class="text-danger" style="font-weight:bold; font-size:15px;">*</span>) son obligatorios.
                                </div>
                            </fieldset>
                            <div class="text-center login-footer-tab">
                                <ul class="nav nav-tabs" id="tab_login" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" data-toggle="tab" :href="'#login'+version" role="tab">
                                            <i class="icofont icofont-lock"></i> INGRESAR
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-toggle="tab" :href="'#register'+version" role="tab">
                                            <i class="icofont icofont-pencil-alt-5"></i> REGISTRAR
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </Modal>
</template>

<script>
    import {mapState, mapMutations, mapActions} from 'vuex';
    import { objectToFormData } from '../formData'
    import Modal from '@/components/Modal.vue';

    export default {
        name:'Login',
        computed:{
            ...mapState(['empresa', 'sucursal_seleccionada']),
        },
        components: {
            Modal,
		},
        created(){
            this.loadObjSucursalSeleccionada();
        },
        props:{
            title_modal:{
                default:"INGRESE A SU CUENTA"
            }
        },
        mounted(){
            this.getTipoCliente();
        },
        data(){
            return{
                formLogin:{
                    nick:"",
                    clave:"",
                    msg:"",
                    status: false,
                },
                errorLogin:{
                    nick:"",
                    clave:"",
                },
                formRegistro:{
                    email:"",
                    telefono_movil:"",
                    tipo_cliente:"N",
                    numero_identificacion:"",
                    clave:"",
                    re_clave:"",
                    error:[],
                    long_tipo_doc_cliente:8,
                    tipo_documento_cliente:"DNI",
                },
                tipo_cliente:[],
                version: Math.floor(Math.random() * 10),
            }
        },
        methods:{
            ...mapMutations(['setLoading', 'setPerfilUsuario', 'setCarritocliente']),
            ...mapActions(['loadObjSucursalSeleccionada']),
            getTipoCliente(){
                this.$http.get('tiendaVirtual/get_tipo_cliente').then((res)=>{
                    this.tipo_cliente = res.data.data;
                }).catch(e => {
                    console.log(e.response);
                }).finally(()=>{
                    this.setLoading(false);
                });
            },
            abrir_login(){
                var self = this
                
                //Mostrando tab loging por defecto
                $('#tab_login.nav-tabs a[href="#login'+this.version+'"]').tab('show');

                //Limpiando el formLogin
                this.formLogin.nick     = "";
                this.formLogin.clave    = "";
                this.formLogin.msg      = "";
                this.formLogin.status   = false;

                this.errorLogin.nick    = "";
                this.errorLogin.clave   = "";

                //Limpiando el formRegistro
                this.formRegistro.email                     = "";
                this.formRegistro.telefono_movil            = "";
                this.formRegistro.numero_identificacion     = "";
                this.formRegistro.tipo_cliente              = "N";
                this.formRegistro.clave                     = "";
                this.formRegistro.re_clave                  = "";
                this.formRegistro.error                     = [];

                this.formRegistro.tipo_documento_cliente    = "DNI";
                this.formRegistro.long_tipo_doc_cliente     = 8;

                self.setLoading(true);
                this.$refs.form_login.open();

                setTimeout(()=>{
                    this.setLoading(false);
                }, 300);
            },
            iniciar_sesion(){
                var self = this;

                if(this.formLogin.nick==""){
                    this.errorLogin.nick="Ingrese usuario";
					this.$refs.nick.focus();
					return;
				}
                 this.errorLogin.nick="";

				if(this.formLogin.clave==""){
                    this.errorLogin.clave="Ingrese clave";
					this.$refs.clave.focus();
					return;
				}
                this.errorLogin.clave="";

                self.setLoading(true);
                let formData = new FormData();
                    formData.append("nick", this.formLogin.nick);
                    formData.append("clave", this.formLogin.clave);
                    formData.append("sucursal", this.sucursal_seleccionada);

                self.$http.post('tiendaVirtual/ingresar', formData).then((res)=>{
                    self.formLogin.status = res.data.status;
                    self.formLogin.msg    = res.data.msg;

                    if(res.data.cli_id && res.data.api_token){
                        this.setPerfilUsuario({
                            cli_id:res.data.cli_id,
                            api_token_carrito:res.data.api_token,
                            nick:res.data.nick,
                            avatar:res.data.avatar,
                            nombres:res.data.nombres,
                            first_name:res.data.firstName,
                        });

                        this.setCarritocliente(res.data.carrito);
                        this.$refs.form_login.close();
                        toastr.success("Bienvenido "+res.data.nombres+"..!!",'Hola', { showDuration: 300, hideDuration: 12000, progressBar: true, closeButton:  true });

                    }else{
                        toastr.error("Usuario o clave incorrecto",'Advertencia', { showDuration: 300, hideDuration: 12000, progressBar: true, closeButton:  true });
                        self.$refs.nick.focus();
                    }
                }).catch(e => {
                    if(e && e.response && e.response.status==422){ //Errores de Validacion
                        const mapErrors ={};
                        for (let field of Object.keys(e.response.data.errors)) {
                            mapErrors[field]=e.response.data.errors[field][0];
                            
                            this.formLogin.msg      = mapErrors[field];
                            this.formLogin.status   = false;
                        }
                    }
                }).finally(()=>{
                    this.setLoading(false);
                });
            },
            //async iniciar_con_google(){
            //    window.open('http://localhost/negocios_claro/public/api/tiendaVirtual/google-auth/login','_blank');
            //},
            cambiar_tipo_documento_cliente(){
                if(this.formRegistro.tipo_cliente=="N"){
                    this.formRegistro.tipo_documento_cliente = "DNI";
                    this.formRegistro.long_tipo_doc_cliente  = 8;
                }else{
                    this.tipo_documento_cliente = "RUC";
                    this.formRegistro.long_tipo_doc_cliente  = 11;
                }
            },
            registrar_cliente(){
                if(this.formRegistro.numero_identificacion==""){
					this.$refs.numero_identificacion_registro.focus();
					return;
				}

                if(this.formRegistro.email==""){
					this.$refs.email_registro.focus();
					return;
				}

                let selfs = this;

                selfs.setLoading(true);
                selfs.$http.post('tiendaVirtual/registrar_cliente', objectToFormData(this.formRegistro)).then((res)=>{
                    console.log(res);

                    if(res.data.id_suscripcion){
                        //this.$refs.form_login.close();
                        toastr.info("Tus credenciales se enviaron al correo "+res.data.usuario,'Hola!', { showDuration: 300, hideDuration: 12000, progressBar: true, closeButton:  true });
                        $('a[href="#login'+this.version+'"]').trigger('click');
                        //setTimeout(()=>{
                            //location.reload();
                        //}, 10000);
                    }
                }).catch(function (e) {
                    if(e && e.response && e.response.status==422){ //Errores de Validacion
                        const mapErrors ={};
                        selfs.formRegistro.error = [];
                        for (let field of Object.keys(e.response.data.errors)) {
                            mapErrors[field]=e.response.data.errors[field][0];

                            selfs.formRegistro.error.push(mapErrors[field]);
                        }
                    }
                }).finally(()=>{
                    selfs.setLoading(false);
                });
            }
        }
    }
</script>